jQuery(document).ready(function($){
  
  if( ! jQuery('body.page-template-template-management').length ) return;
  
  
  $("div[id^='myModal']").each(function(){
    
    var currentModal = $(this);
    
    //click next
    currentModal.find('.modal-nav-btn.next').click(function(){
      currentModal.modal('hide');
      $('.modal-backdrop').remove();
      currentModal.closest("div[id^='myModal']").nextAll("div[id^='myModal']").first().modal('show'); 
    });
    
    //click prev
    currentModal.find('.modal-nav-btn.prev').click(function(){
      currentModal.modal('hide');
      $('.modal-backdrop').remove();
      currentModal.closest("div[id^='myModal']").prevAll("div[id^='myModal']").first().modal('show'); 
    });

  });


});
