jQuery(document).ready(function($){
  
  if( ! jQuery('body.page-template-template-downloads').length ) return;
  


  smoothScroll.init({
    offset: 80,
    speed: 1000
  });


});
