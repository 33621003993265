jQuery(document).ready(function($){
  
  if( ! jQuery('body.page-template-template-blog').length ) return;
  
  
  var main_slider = document.getElementById("blog_sequence");
  var $main_slider = $(main_slider);
  
  if( $main_slider.size() > 0 ) {
    
    var auto     = $('#blog_sequence').data('auto');
    var interval = $('#blog_sequence').data('interval');
    
    var blogSequence = sequence(main_slider, {
      animateCanvas: false,
      keyNavigation: true,
      autoPlay: auto,
      autoPlayInterval: (interval * 1000),
      // animateCanvasDuration: 1400,
      pagination: '.blog-sequence-pagination'
    });
    
    console.log( auto );
  }
  
  
  
  
});
